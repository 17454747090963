import { format, formatDistance, parseISO } from 'date-fns';
import it from 'date-fns/locale/it';

/* Dates */
export const formatDate = (date) =>
  format(date, 'dd MMMM yyyy HH:mm', { locale: it });

export const formatToNow = (date) => {
  try {
    return formatDistance(parseISO(date), new Date(), { locale: it }) + ' fa';
  } catch (error) {
    console.error('Invalid date', error);
  }
};

export const formatInstagramDate = (date) => formatDate(new Date(date * 1000));

export const formatFlickrDate = (date) => formatDate(new Date(date));

export const formatTwitterDate = (date) =>
  format(new Date(date), 'dd MMM, yyyy', { locale: it });
